import { FC, useEffect, useState } from 'react';

import { useMsal } from '@azure/msal-react';
import {
  Alert,
  Avatar,
  Box,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Radio,
  Snackbar,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { BottomNavMenu, Popup } from '../../components';

import i18n from '../../languages';
import styles from './me.module.scss';

import 'moment/locale/zh-tw';

import { useDispatch, useSelector } from 'react-redux';

import { loginRequest } from '../../config/authConfig';
import { clearUserState, UserState } from '../../reducers/user-slice';
import {
  postUserLogout,
  setInstanceLanguage,
} from '../../services/api-services';
import { getPartnerCherriesSummary } from '../../services/leaderboard';
import { RootState } from '../../store/store';
import {
  addThousandSeparator,
  getDefaultLanguageByRegion,
  getLanguageByRegion,
  handleLogoutAction,
  nth,
} from '../../utility';

interface settingsType {
  id: string;
  label: string;
  content?: string;
  icon: string;
  disable?: boolean;
  onClick: () => void;
}

const MeSection: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [openSignOutPopup, setOpenSignOutPopup] = useState(false);
  const [language, setLanguage] = useState(
    getDefaultLanguageByRegion(i18n.language)
  );
  const [openLanguageDropDown, setOpenLanguageDropDown] = useState(false);
  const dispatch = useDispatch();
  const userState = useSelector(
    (state: RootState): UserState => state.userState
  );
  const [userSummary, setUserSummary] = useState({
    cherries: 0,
    rank: 0,
  });
  const languageList = [
    {
      name: t('general.languages.en'),
      value: 'EN',
    },
    {
      name: t('general.languages.tc'),
      value: 'TC',
    },
  ];

  const { instance } = useMsal();

  const handleRedirect = (url: string) => {
    navigate(url);
  };

  const handleSignOut = async () => {
    try {
      await postUserLogout({
        MS_TOKEN: localStorage.getItem('MS_TOKEN') ?? null,
      });
      if (localStorage.getItem('IS_SB_SSO') === 'true') {
        const url = `${process.env.REACT_APP_SSO_AUTH_URL}public/signout.aspx?wa=wsignout1.0&wreply=/ofis/public/sbuxLogOut.html`; // Replace with the actual URL
        const newWindow = window.open(url, '_blank');
        if (newWindow) {
          newWindow.focus();
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setOpenSignOutPopup(false);
      dispatch(clearUserState());
      console.log('handleSignOut');
      handleLogoutAction();
    }
    // setTimeout(() => {
    //   window.location.reload();
    // }, 300);
  };

  const shortCutListTW: Array<settingsType> = [
    {
      id: 'calendarShortCutButton',
      label: t('myProfile.me.calendar'),
      icon: '/assets/images/shortcutMenu_calendar.svg',
      onClick: () => {
        handleRedirect('/calendar');
      },
    },
    {
      id: 'rosterShortCutButton',
      label: t('myProfile.me.roster'),
      icon: '/assets/images/shortcutMenu_roster.svg',
      onClick: () => {
        if (process.env.REACT_APP_LOCATION === 'TWN') {
          handleRedirect('/roster');
        } else {
          const win = window.open(
            'https://partnerconnect.ph/',
            '_blank',
            'noopener noreferrer'
          );
          if (win != null) {
            win.focus();
          }
        }
      },
    },
    {
      id: 'greenApronCardShortCutButton',
      label: t('myProfile.me.greenApronCard'),
      icon: '/assets/images/menu_greenApronCard.svg',
      onClick: () => handleRedirect('/green-apron-card'),
    },
    {
      id: 'myRewardShortCutButton',
      label: t('myProfile.me.myReward'),
      icon: '/assets/images/menu_myRewards.svg',
      onClick: () => handleRedirect('/leaderboard/my-rewards'),
    },
  ];

  const shortCutListHK: Array<settingsType> = [
    {
      id: 'calendarShortCutButton',
      label: t('myProfile.me.calendar'),
      icon: '/assets/images/shortcutMenu_calendar.svg',
      onClick: () => {
        // handleRedirect('/calendar');
        window.location.href = '/calendar';
      },
    },
    {
      id: 'myRankingShortCutButton',
      label: t('myProfile.me.myRanking'),
      icon: '/assets/images/shortcutMenu_rankings.svg',
      onClick: () => {
        handleRedirect('/leaderboard/rankings');
      },
    },
    {
      id: 'greenApronCardShortCutButton',
      label: t('myProfile.me.greenApronCard'),
      icon: '/assets/images/menu_greenApronCard.svg',
      onClick: () => handleRedirect('/green-apron-card'),
    },
    {
      id: 'myRewardShortCutButton',
      label: t('myProfile.me.myReward'),
      icon: '/assets/images/menu_myRewards.svg',
      onClick: () => handleRedirect('/leaderboard/my-rewards'),
    },
  ];

  const shortCutListPHL: Array<settingsType> = [
    {
      id: 'discoverPeopleShortCutButton',
      icon: '/assets/images/shortcutMenu_discoverPeople.svg',
      label: t('homepage.discoverPeople'),
      onClick: () => {
        handleRedirect('/discover-people');
      },
    },
    {
      id: 'calendarShortCutButton',
      icon: '/assets/images/shortcutMenu_calendar.svg',
      label: t('homepage.calendar'),
      onClick: () => {
        handleRedirect('/calendar');
      },
    },
    {
      id: 'partnerConnectShortCutButton',
      icon: '/assets/images/linkIcon_PartnerConnect.svg',
      label: t('homepage.usefulLinksText.partnerConnect'),
      onClick: () => {
        const win = window.open(
          'https://partnerconnect.ph/',
          '_blank',
          'noopener noreferrer'
        );
        if (win != null) {
          win.focus();
        }
      },
    },
    {
      id: 'greenApronCardShortCutButton',
      icon: '/assets/images/menu_greenApronCard.svg',
      label: t('homepage.apronCard'),
      onClick: () => handleRedirect('/green-apron-card'),
    },
  ];

  const displayShortCutList =
    process.env.REACT_APP_LOCATION === 'TWN'
      ? shortCutListTW
      : process.env.REACT_APP_LOCATION === 'PHL'
        ? shortCutListPHL
        : shortCutListHK;

  let settingsList: Array<settingsType> = [
    {
      id: 'aboutUsButton',
      label: t('myProfile.me.aboutUs'),
      icon: '/assets/images/eventIcon_circle_information.svg',
      onClick: () => handleRedirect('/me/about-us'),
    },
    {
      id: 'termsConditionsButton',
      label: t('myProfile.me.termsConditions'),
      icon: '/assets/images/file_alt.svg',
      onClick: () => handleRedirect('/me/terms-conditions'),
    },
    {
      id: 'contactUsButton',
      label: t('myProfile.me.contactUs'),
      icon: '/assets/images/headphones_alt.svg',
      onClick: () => handleRedirect('/me/contact-us'),
    },
  ];

  if (process.env.REACT_APP_LOCATION === 'TWN') {
    settingsList = [
      ...settingsList,
      {
        id: 'languageButton',
        label: t('myProfile.me.language'),
        content: languageList.find((lang) => lang.value === language)?.name,
        icon: '/assets/images/language.svg',
        onClick: () => setOpenLanguageDropDown(true),
      },
    ];
  }

  if (
    process.env.REACT_APP_LOCATION === 'HK' &&
    !localStorage.getItem('MS_TOKEN')
  ) {
    settingsList = [
      ...settingsList,
      {
        id: 'teamsAuthButton',
        label: t('myProfile.me.teamsAuth'),
        icon: '/assets/images/language.svg',
        onClick: () => {
          sessionStorage.setItem(
            'IS_REQUEST_AUTH',
            `${window.location.origin}/home?request=LOGIN_SUCCESS`
          );
          instance.loginRedirect(loginRequest).catch((e: any) => {
            console.error('loginRedirect', e);
          });
        },
      },
    ];
  }

  const otherList: Array<settingsType> = [
    {
      id: 'signOutButton',
      label: t('myProfile.me.signOut'),
      icon: '/assets/images/door_open.svg',
      onClick: () => setOpenSignOutPopup(true),
    },
  ];

  const [searchParams] = useSearchParams();
  const [sentBar, setSentBar] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('AAD_LOGIN') === 'true') {
      // Remove AAD_LOGIN from localStorage after login success
      localStorage.removeItem('AAD_LOGIN');
      window.location.reload();
    }
  }, []);

  useEffect(() => {
    const getSummary = async () => {
      try {
        const response = await getPartnerCherriesSummary();
        if (response.status === 200) {
          const summary = response.data.partnerCherries;
          if (summary) {
            setUserSummary({
              cherries: summary.cherries,
              rank: summary.rank,
            });
          }
        }
      } catch (error) {
        throw error;
      }
    };

    if (searchParams.has('sent')) {
      window.history.replaceState(null, '', '/me');
      setSentBar(true);
    }

    getSummary();
  }, [searchParams]);

  return (
    <>
      <Box className={styles.headerSection}>
        <Grid container alignItems="center" className={styles.userSection}>
          <Grid item xs="auto">
            <Avatar
              alt={userState.name}
              src={userState.avatar ?? ''}
              className={styles.userAvatar}
            />
          </Grid>
          <Grid item xs className={styles.userInfo}>
            <Typography variant="h4">{userState.name}</Typography>
            <Typography variant="body1">{userState.jobTitle}</Typography>
          </Grid>
          <Grid item xs="auto" className={styles.userArrow}>
            <IconButton
              disableRipple
              onClick={() => navigate('/me/my-profile')}
            >
              <img src="/assets/images/chevron_right_white.svg" alt="" />
            </IconButton>
          </Grid>
        </Grid>
        <ScoreSection
          cherriesPoint={userSummary.cherries}
          rankingPoint={userSummary.rank}
        />
      </Box>
      <Grid
        container
        direction="column"
        flexWrap="nowrap"
        item
        xs
        className={styles.container}
      >
        <Grid
          container
          className={`${styles.section} ${styles.sectionShortCutList}`}
        >
          {displayShortCutList.map((settings: settingsType, index: number) => (
            <Grid key={index} item xs={3} className={styles.sectionShortCut}>
              <ShortCutButton {...settings} />
            </Grid>
          ))}
        </Grid>
        <Box className={styles.section}>
          <Typography variant="h5" className={styles.sectionTitle}>
            {t('myProfile.me.settings')}
          </Typography>
          <List disablePadding className={styles.sectionList}>
            {settingsList.map((settings: settingsType, index: number) => (
              <SettingButton key={index} {...settings} />
            ))}
          </List>
        </Box>
        <Box className={styles.section}>
          <List disablePadding className={styles.sectionList}>
            {otherList.map((settings: settingsType, index: number) => (
              <SettingButton key={index} {...settings} />
            ))}
          </List>
        </Box>
      </Grid>
      <BottomNavMenu />
      <Popup
        isOpen={openLanguageDropDown}
        title={
          <Grid container alignItems="center">
            <Grid item xs>
              <Typography variant="h4" color="text.primary">
                {t('myProfile.me.language')}
              </Typography>
            </Grid>
            <Grid item xs="auto">
              <IconButton
                disableRipple
                size="small"
                className={styles.closeButton}
                onClick={() => setOpenLanguageDropDown(false)}
              >
                <img src="/assets/images/close_btn.svg" alt="" />
              </IconButton>
            </Grid>
          </Grid>
        }
        content={
          <List disablePadding className={styles.sectionList}>
            {languageList.map(
              (lang: { name: string; value: string }, index: number) => (
                <ListItem key={index} disablePadding>
                  <ListItemButton
                    className={styles.sectionItem}
                    onClick={() => {
                      setLanguage(lang.value);
                      setInstanceLanguage(
                        lang.value.toLowerCase() === 'tc' ? 'tc' : 'en'
                      );

                      if (lang.value.toLowerCase() === 'tc') {
                        moment.locale('zh-tw');
                      } else {
                        moment.locale('en');
                      }

                      i18n.changeLanguage(getLanguageByRegion(lang.value));
                    }}
                  >
                    <ListItemText
                      id={`languageButton_${lang.value}`}
                      primary={lang.name}
                      primaryTypographyProps={{ color: 'text.primary' }}
                    />
                    <ListItemIcon>
                      <Radio
                        disableRipple
                        checked={language === lang.value}
                        inputProps={{ 'aria-labelledby': lang.value }}
                        tabIndex={-1}
                        className={styles.optionRadio}
                      />
                    </ListItemIcon>
                  </ListItemButton>
                </ListItem>
              )
            )}
          </List>
        }
        disableActions
        setIsOpen={(isOpen: boolean) => setOpenLanguageDropDown(isOpen)}
      />
      <Popup
        isOpen={openSignOutPopup}
        title={t('myProfile.signOut.title')}
        content={t('myProfile.signOut.description')}
        setIsOpen={(isOpen: boolean) => setOpenSignOutPopup(isOpen)}
        confirmBtnText={t('myProfile.signOut.signOut')}
        onClickConfirm={() => handleSignOut()}
      />
      <Snackbar
        open={sentBar}
        autoHideDuration={6000}
        onClose={() => setSentBar(false)}
      >
        <Alert severity="success">{`Sent`}</Alert>
      </Snackbar>
    </>
  );
};

const ShortCutButton: FC<settingsType> = (props) => {
  return (
    <Link
      id={props.id}
      component="button"
      underline="none"
      onClick={props.onClick}
    >
      <img src={props.icon} alt="" />
      <Typography variant="body2" color="black.opacity87">
        {props.label}
      </Typography>
    </Link>
  );
};

const SettingButton: FC<settingsType> = (props) => {
  return (
    <ListItem disablePadding>
      <ListItemButton
        id={props.id}
        component="button"
        className={styles.sectionItem}
        onClick={() => (!props.disable ? props.onClick() : false)}
      >
        <ListItemIcon>
          <img src={props.icon} alt="" />
        </ListItemIcon>
        <ListItemText
          primary={props.label}
          primaryTypographyProps={{ color: 'text.primary' }}
          secondary={props.content}
          secondaryTypographyProps={{ color: 'black.black038' }}
          className={styles.sectionLabel}
        />
        {props.disable ? null : (
          <img src="/assets/images/chevron_right_black_v2.svg" alt="" />
        )}
      </ListItemButton>
    </ListItem>
  );
};

interface ScoreSectionProp {
  cherriesPoint: number;
  rankingPoint: number;
}

interface ScoreButtonProp {
  id: string;
  label: string;
  point: number | string;
  url: string;
  unit: string;
}

const ScoreSection: FC<ScoreSectionProp> = (props) => {
  const { t } = useTranslation();
  const scoreButtonList: Array<ScoreButtonProp> = [
    {
      id: 'coffeeCherriesButton',
      label: t('myProfile.me.coffeeCherries'),
      point: addThousandSeparator(props.cherriesPoint),
      url: '/leaderboard',
      unit: t('myProfile.me.points'),
    },
    {
      id: 'myRankingButton',
      label: t('myProfile.me.myRanking'),
      point: addThousandSeparator(props.rankingPoint),
      url: '/leaderboard/rankings',
      unit: nth(props.rankingPoint),
    },
  ];

  return (
    <Box className={styles.score}>
      <Grid container alignItems="center" className={styles.scoreBox}>
        <Grid item xs="auto">
          <img src="/assets/images/offers.png" alt="" />
        </Grid>
        <Grid item xs>
          <Grid container className={styles.scoreList}>
            {scoreButtonList.map(
              (scoreButton: ScoreButtonProp, index: number) => (
                <Grid key={index} item xs={6}>
                  <ScoreButton {...scoreButton} />
                </Grid>
              )
            )}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

const ScoreButton: FC<ScoreButtonProp> = (props) => {
  const navigate = useNavigate();

  const handleScoreButtonClick = (url: string) => {
    navigate(url);
  };

  return (
    <Link
      id={props.id}
      component="button"
      underline="none"
      className={styles.scoreItem}
      onClick={() => handleScoreButtonClick(props.url)}
    >
      <Typography variant="h3" color="primary">
        {props.point}
        <Typography variant="body2" component="span" color="text.primary">
          {props.unit}
        </Typography>
      </Typography>
      <Grid container alignItems="center">
        <Grid item xs>
          <Typography variant="body2" color="text.primary">
            {props.label}
          </Typography>
        </Grid>
        <Grid item xs="auto">
          <img src="/assets/images/chevron_right_black_v2.svg" alt="" />
        </Grid>
      </Grid>
    </Link>
  );
};

export default MeSection;
