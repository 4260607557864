import React, { useEffect, useState } from 'react';

import { useMsal } from '@azure/msal-react';
import {
  Alert,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  Snackbar,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Checkbox, Popup, RoundButton } from '../../components';
import InputField from '../../components/input-field';

import { loginRequest } from '../../config/authConfig';
import { setUserState, UserState } from '../../reducers/user-slice';
import {
  getPermission,
  postSSOAuthCodeHK,
  postUserLoginTW,
  setAuthorizationToken,
} from '../../services/api-services';
import { getChatConfig } from '../../services/homepage';
import { getUserInfo } from '../../services/user';
import { convertUserProfile } from '../../utility';
import styles from './login.module.scss';

function Login() {
  const { t } = useTranslation();
  const { instance } = useMsal();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingSSO, setIsLoadingSSO] = useState<boolean>(false);
  const [searchParams] = useSearchParams();
  // const [isFirstLogin, setIsFirstLogin] = useState<boolean>(
  //   searchParams.get("FirstLogIn") ? true : false
  // );
  const [loginFailStatus, setLoginFailStatus] = useState({
    open: false,
    message: '',
  });

  const location = process.env.REACT_APP_LOCATION;
  const [partnerId, setPartnerId] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [isEmpty, setIsEmpty] = useState<boolean>(true);
  const [error, setError] = useState<boolean>(false);
  const [openPopup, setOpenPopup] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const [isRememberMe, setIsRememberMe] = useState<boolean>(false);
  const [redirectPath, setRedirectPath] = useState<string | null>(null);

  useEffect(() => {
    if (searchParams.get('loginType') === 'AD') {
      localStorage.setItem('AAD_LOGIN', 'true');
      localStorage.setItem('IS_SB_SSO', 'false');
    }

    if (searchParams.get('code')) {
      console.log('code:', searchParams.get('code'));
      window.history.replaceState(null, '', '/login');
      setIsLoadingSSO(true);

      postSSOAuthCodeHK({
        code: searchParams.get('code') as string,
        redirect_uri: `${process.env.REACT_APP_AZURE_REDIRECT}`,
      })
        .then(async (response) => {
          console.log('postSSOAuthCodeHK response:', response);
          if (response.data.MS_TOKEN) {
            console.log('Storage SSO MS_TOKEN');
            localStorage.setItem('MS_TOKEN', response.data.MS_TOKEN);
          }
          setAuthorizationToken(response.data.access_token);
          await getInfo({
            accessToken: response.data.access_token,
            refreshToken: response.data.refresh_token,
            isAcceptedTnc: response.data.isAcceptedTnc,
          });
        })
        .catch((error) => {
          setIsLoading(false);
          setIsLoadingSSO(false);
          setError(true);
          setLoginFailStatus({
            open: true,
            message: 'Starbucks SSO login Fail.',
          });
        });
    }

    if (searchParams.get('status')) {
      if (searchParams.get('status') === '4083') {
        setLoginFailStatus({
          open: true,
          message: t('login.loginFail4083'),
        });
      } else {
        setLoginFailStatus({
          open: true,
          message: t('login.loginFail'),
        });
      }
      window.history.replaceState(null, '', '/login');
    }

    if (
      searchParams.get('initLocation') &&
      searchParams.get('initLocation') !== '/login'
    ) {
      const pathname = searchParams.get('initLocation');
      setRedirectPath(pathname);
      window.history.replaceState(null, '', '/login');
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress, false);
    return () => {
      document.removeEventListener('keydown', handleKeyPress, false);
    };
  }, [redirectPath]);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleKeyPress = (event: any) => {
    if (event.key === 'Enter' && location === 'TWN') {
      login();
    }
  };

  const login = async () => {
    if (!isLoading) {
      setIsLoading(true);
      if (location === 'TWN') {
        await postUserLoginTW({
          grant_type: 'password',
          userId: partnerId
            ? partnerId
            : (document.getElementById('partnerIdInput') as HTMLInputElement)
                .value,
          password: password
            ? password
            : (document.getElementById('passwordInput') as HTMLInputElement)
                .value,
        })
          .then(async (response) => {
            setAuthorizationToken(response.data.access_token);
            await getInfo({
              accessToken: response.data.access_token,
              refreshToken: response.data.refresh_token,
              isAcceptedTnc: response.data.isAcceptedTnc,
            });
          })
          .catch((error) => {
            setIsLoading(false);
            setError(true);
          });
      } else if (
        location === 'PHL' ||
        (location === 'HK' && searchParams.get('loginType') === 'AD')
      ) {
        localStorage.setItem('AAD_LOGIN', 'true');
        localStorage.setItem('IS_SB_SSO', 'false');
        if (window.location.hostname === 'localhost') {
          window.location.href = `/login?request=testLocalhost`;
        } else {
          instance.loginRedirect(loginRequest).catch((e) => {
            console.error('loginRedirect', e);
          });
        }
      }
      // else if (location === 'HK') {
      //   window.location.href = `${process.env.REACT_APP_SSO_AUTH_URL}?response_type=code&client_id=${process.env.REACT_APP_SSO_CLIENT_ID}&scope=openid%20profile&redirect_uri=${process.env.REACT_APP_AZURE_REDIRECT}`;
      // }
    }
  };

  const getInfo = async (token: {
    accessToken: string;
    refreshToken: string;
    isAcceptedTnc: boolean;
  }) => {
    try {
      const permissionResponse = await getPermission();
      const response = await getUserInfo();
      const chatConfigResponse = await getChatConfig();
      const { userProfile } = response.data;
      let userInfo: UserState = convertUserProfile(userProfile) as UserState;

      userInfo = {
        ...userInfo,
        permission: permissionResponse.data,
        chat: chatConfigResponse.data,
        accessToken: token.accessToken,
        refreshToken: token.refreshToken ?? '',
        isAcceptedTnc: token.isAcceptedTnc,
      };
      dispatch(setUserState(userInfo));
      handleRememberMe();
      setIsLoading(false);
      if (token.isAcceptedTnc) {
        if (redirectPath) {
          navigate(redirectPath);
        } else {
          navigate(`/home`);
        }
      } else {
        navigate(`/first-login`);
      }
    } catch (error) {
      setAuthorizationToken('');
      setError(true);
      setIsLoading(false);
    }
  };

  const handleEnterID = (event: any) => {
    setPartnerId(event.target.value);
    if (event.target && event.target.value.length !== 0 && password) {
      setIsEmpty(false);
    } else {
      setIsEmpty(true);
    }
  };

  const handleEnterPW = (event: any) => {
    setPassword(event.target.value);
    if (event.target && event.target.value.length !== 0 && partnerId) {
      setIsEmpty(false);
    } else {
      setIsEmpty(true);
    }
  };

  // function for remember me function to save partnerIdInput value (use high security method)
  const handleRememberMe = () => {
    if (isRememberMe) {
      localStorage.setItem('partnerId', partnerId);
    } else {
      localStorage.removeItem('partnerId');
    }
  };

  useEffect(() => {
    const rememberPartnerId = localStorage.getItem('partnerId');
    if (rememberPartnerId) {
      setPartnerId(rememberPartnerId);
      setIsRememberMe(true);
    }
  }, [localStorage]);

  return (
    <>
      <Box className={styles.pageWrapper}>
        <img
          className={styles.logo}
          src="/assets/images/starbucks_logo.png"
          alt=""
        />
        <Box className={styles.text}>
          <Typography variant="h1">
            {t('login.welcomeMsg1')}
            <br />
            <b>{t('login.welcomeMsg2')}</b>
            <br />
            <b>{t('login.welcomeMsg3')}</b>
          </Typography>
        </Box>
        {location === 'TWN' && (
          <Box className={styles.formWrapper}>
            <Box className={styles.InputContainer}>
              <InputField
                id="partnerIdInput"
                name="partnerIdInput"
                placeholder={t('login.partnerId')}
                value={partnerId}
                error={error ? true : false}
                required
                onChange={handleEnterID}
              />
              <InputField
                id="passwordInput"
                name="passwordInput"
                placeholder={t('login.password')}
                helperText={error ? t('login.invalidIDPassword') : ''}
                value={password}
                error={error ? true : false}
                required
                type={showPassword ? 'text' : 'password'}
                onChange={handleEnterPW}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? (
                        <img src="/assets/images/eye_slash.svg" />
                      ) : (
                        <img src="/assets/images/eye.svg" />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              <Grid container className={styles.functionContainer}>
                <Grid item xs="auto">
                  <Checkbox
                    text={t('login.remember')}
                    checked={isRememberMe}
                    setChecked={(checked: boolean) => {
                      setIsRememberMe(checked);
                    }}
                  />
                </Grid>
                <Grid item xs="auto">
                  <Typography
                    variant="button"
                    className={styles.forgotPw}
                    onClick={() => setOpenPopup(true)}
                  >
                    {t('login.forgot')}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
            <RoundButton disabled={isEmpty ? true : false} onClick={login}>
              {isLoading ? (
                <CircularProgress color="inherit" size="16px" />
              ) : (
                t('login.login')
              )}
            </RoundButton>
          </Box>
        )}
        {(location === 'PHL' || location === 'HK') && (
          <>
            {isLoadingSSO ? (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  height: '200px',
                }}
              >
                <CircularProgress color="success" size="32px" />
              </div>
            ) : (
              <>
                {searchParams.get('loginType') === 'AD' ? (
                  <RoundButton onClick={login}>
                    {t('login.loginSSO') + '  - MS AAD'}
                  </RoundButton>
                ) : (
                  <a
                    href={`${process.env.REACT_APP_SSO_AUTH_URL}?response_type=code&client_id=${process.env.REACT_APP_SSO_CLIENT_ID}&scope=openid%20profile&redirect_uri=${process.env.REACT_APP_AZURE_REDIRECT}`}
                    onClick={() => {
                      localStorage.setItem('IS_SB_SSO', 'true');
                    }}
                    style={{
                      textDecoration: 'none',
                      color: 'white',
                      backgroundColor: '#00754A',
                      fontSize: '15px',
                      fontWeight: '600',
                      width: '100%',
                      padding: '16px',
                      borderRadius: '41px',
                      textAlign: 'center',
                      display: 'block',
                    }}
                  >
                    {t('login.loginSSO')}
                  </a>
                )}
              </>
            )}
          </>
        )}
      </Box>
      <Snackbar
        open={loginFailStatus.open}
        autoHideDuration={6000}
        onClose={() =>
          setLoginFailStatus((prev) => ({
            ...prev,
            open: false,
          }))
        }
      >
        <Alert severity="error">{loginFailStatus.message}</Alert>
      </Snackbar>
      <Popup
        isOpen={openPopup}
        setIsOpen={setOpenPopup}
        title={t('login.forgotPwPopup') as string}
      />
    </>
  );
}

export default Login;
