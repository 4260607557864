import React, { FC, useEffect, useState } from 'react';

import { Grid, Skeleton, Stack, Typography } from '@mui/material';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';

import { NoResults } from '../../components';
import Header from '../../components/header/header';

import { ObjectKey } from '../../interfaces/common-interface';
import {
  GreenApronCardDetailProps,
  RepliedType,
} from '../../interfaces/green-apron-card-interface';
import {
  changeReplyRead,
  getSentCardsList,
} from '../../services/green-apron-card';
import {
  convertDateFullTimeWithFullMonth,
  convertDateWithFullMonth,
} from '../../utility';
import styles from './greenApronCard.module.scss';

const GreenApronCardSent: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [cardList, setCardList] = useState<Array<GreenApronCardDetailProps>>(
    []
  );
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isEnded, setIsEnded] = useState<boolean>(false);
  const [apiPagination, setApiPagination] = React.useReducer(
    (prev: PaginationType, next: Partial<PaginationType>) => {
      return { ...prev, ...next };
    },
    {
      page: 1,
      pageSize: 10,
    }
  );

  useEffect(() => {
    let active = true;

    const getList = async () => {
      try {
        const response = await getSentCardsList({
          page: apiPagination.page,
          pageSize: apiPagination.pageSize,
        });
        if (response.status === 200) {
          if (response.data.isEnded) {
            setIsEnded(true);
          }
          const { sentCards } = response.data;

          if (sentCards && sentCards.length > 0) {
            const list = sentCards
              .sort((a: ObjectKey, b: ObjectKey) => {
                return b.sendTime - a.sendTime;
              })
              .map((item: ObjectKey) => {
                let repliedObject: RepliedType | null = null;
                if (item.cardReplyMessage) {
                  repliedObject = {
                    name: '',
                    avatar: '',
                    msg: item.cardReplyMessage,
                    date: item.lastUpdateTime,
                  };
                }

                return {
                  id: item._id,
                  receiver: {
                    id: item.receiverId,
                    name: item.receiver,
                    avatar: '',
                  },
                  sender: {
                    id: item.senderId,
                    name: item.sender,
                    avatar: '',
                  },
                  description: item.senderMessage ?? '',
                  sendDate: item.sendTime,
                  cardInfo: {
                    id: '',
                    name: '',
                    description: item.cardDefaultMessage,
                    url: item.thumbUrl,
                  },
                  isReplied: Boolean(item.cardReplyMessage),
                  isRead: item.isReplyRead,
                  status: item.status,
                  repliedObject,
                };
              });

            if (active) {
              setCardList(list);
            }
          }
        }
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
        throw error;
      }
    };

    getList();

    return () => {
      active = false;
    };
  }, []);

  async function fetchMoreData() {
    try {
      const response = await getSentCardsList({
        page: apiPagination.page,
        pageSize: apiPagination.pageSize,
      });
      const tempCardList = response.data.sentCards.map((item: ObjectKey) => {
        let repliedObject: RepliedType | null = null;
        if (item.cardReplyMessage) {
          repliedObject = {
            name: '',
            avatar: '',
            msg: item.cardReplyMessage,
            date: item.lastUpdateTime,
          };
        }

        return {
          id: item._id,
          receiver: {
            id: item.receiverId,
            name: item.receiver,
            avatar: '',
          },
          sender: {
            id: item.senderId,
            name: item.sender,
            avatar: '',
          },
          description: item.senderMessage ?? '',
          sendDate: item.sendTime,
          cardInfo: {
            id: '',
            name: '',
            description: item.cardDefaultMessage,
            url: item.thumbUrl,
          },
          isReplied: Boolean(item.cardReplyMessage),
          isRead: item.isReplyRead,
          status: item.status,
          repliedObject,
        };
      });

      setCardList((prev) => [...prev, ...tempCardList]);
      setApiPagination({ page: apiPagination.page + 1 });
      if (response.data.isEnded) {
        setIsEnded(true);
      }
    } catch (error) {
      console.error(error);
    }
  }

  const handleClickCard = (id: string) => {
    changeReplyRead(id);
    navigate(`/green-apron-card/${id}`);
  };

  const ListItem = (props: {
    card: GreenApronCardDetailProps;
    onClick: (id: string) => void;
  }) => {
    const { card } = props;

    const sendDate = card.sendDate
      ? card.status === 'SCHEDULED'
        ? convertDateFullTimeWithFullMonth(moment.unix(card.sendDate).format())
        : convertDateWithFullMonth(moment.unix(card.sendDate).format())
      : '';
    const dateMsg =
      card.status === 'SCHEDULED'
        ? t('greenApronCard.willBeSentOn', {
            date: sendDate,
          })
        : sendDate;

    const StatusIcon = () => {
      if (card.status === 'RECEIVED') return null;

      let iconPath = '/assets/images/greenApronCard_status_failed.svg';
      if (card.status === 'SCHEDULED') {
        iconPath = '/assets/images/greenApronCard_status_scheduled.svg';
      }

      return (
        <Grid item className={styles.sentItemStatus}>
          <img src={iconPath} alt="" />
        </Grid>
      );
    };

    return (
      <Grid
        id={`card-${card.id}`}
        container
        alignItems="center"
        className={styles.sentItem}
        onClick={() => props.onClick(card.id)}
      >
        <Grid item className={styles.sentItemRepliedMark}>
          {card.isReplied && !card.isRead ? <span /> : null}
        </Grid>
        <Grid item className={styles.sentItemImage}>
          <img src={card.cardInfo.url} alt={card.cardInfo.name} />
        </Grid>
        <Grid item xs className={styles.sentItemInfo}>
          <Grid container gap={0.5}>
            <Grid item xs="auto">
              <Typography variant="body1">{t('greenApronCard.to')}</Typography>
            </Grid>
            <Grid item xs>
              <Typography variant="h5">{card.receiver.name}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body2" className={styles.sentItemInfoDate}>
                {dateMsg}
              </Typography>
            </Grid>
            {card.isReplied && (
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  className={styles.sentItemInfoRepliedMsg}
                >
                  {t('greenApronCard.replied', {
                    content: card.repliedObject ? card.repliedObject.msg : '',
                  })}
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
        <StatusIcon />
      </Grid>
    );
  };

  const LoadingSkeleton = () => {
    return (
      <Grid container alignItems="center" className={styles.sentItem}>
        <Grid item className={styles.sentItemRepliedMark} />
        <Grid item className={styles.sentItemImage}>
          <Skeleton
            variant="rounded"
            className={styles.sentItemImageSkeleton}
          />
        </Grid>
        <Grid item xs className={styles.sentItemInfo}>
          <Grid container>
            <Grid item xs={12}>
              <Skeleton variant="text" />
            </Grid>
            <Grid item xs={12}>
              <Skeleton variant="text" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={styles.sentItemStatus} />
      </Grid>
    );
  };

  return (
    <>
      <Header
        enableBackButton
        closeButtonNavigation="/green-apron-card"
        disableBottomBorder
        title={t('greenApronCard.cardSent')}
      />
      <Grid
        item
        xs
        className={styles.sentAndReceivedContainer}
        id="scrollableDiv"
      >
        {!isLoading && cardList.length === 0 ? (
          <NoResults />
        ) : (
          <Stack className={styles.sentList}>
            {isLoading &&
              Array.from(Array(4), (_, index) => (
                <LoadingSkeleton key={index} />
              ))}
            <InfiniteScroll
              dataLength={cardList.length}
              next={fetchMoreData}
              hasMore={!isEnded}
              loader={<LoadingSkeleton />}
              scrollableTarget="scrollableDiv"
            >
              {cardList.map(
                (card: GreenApronCardDetailProps, index: number) => (
                  <ListItem
                    key={index}
                    card={card}
                    onClick={(id: string) => handleClickCard(id)}
                  />
                )
              )}
            </InfiniteScroll>
          </Stack>
        )}
      </Grid>
    </>
  );
};

export default GreenApronCardSent;
