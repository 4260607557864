import { AxiosPromise, AxiosResponse } from "axios";
import { instance } from "./api-services";
import { ObjectKey } from "../interfaces/common-interface";
import { compressFileList } from "../utility";

interface FeedbackRequestData {
  name: string;
  phoneNo: string;
  email: string;
  issue: string;
  comment: string;
  images: ObjectKey[];
}

const servicePath = "/directory";

export const getUserInfo = (): AxiosPromise =>
  instance({
    method: "GET",
    id: `getUserInfo`,
    cache: { ttl: 15 * 60 * 1000 },
    url: `${servicePath}/profile`,
  });

export const updateUserProfile = (data: ObjectKey): AxiosPromise =>
  instance({
    method: "POST",
    cache: {
      update: {
        getUserInfo: "delete",
      },
    },
    url: `${servicePath}/profile`,
    data,
  });

export const createUserCover = (data: { image: File }): AxiosPromise => {
  const formData = new FormData();
  formData.append("image", data.image);

  return instance({
    method: "POST",
    url: `${servicePath}/profile/coverPhoto`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  });
};

export const createUserPicture = async (data: {
  image: File;
}): Promise<AxiosResponse> => {
  const formData = new FormData();
  try {
    const fileList = await compressFileList([data.image], 0.2, 256);
    fileList.forEach((item: File | undefined) => {
      if (item) formData.append("image", item, encodeURIComponent(item.name));
    });
  } catch (error) {
    console.log("compressFileList error:", error);
  }

  return instance({
    method: "POST",
    url: `${servicePath}/profile/profilePicture`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  });
};

export const getProfileAttributes = (): AxiosPromise =>
  instance({
    method: "GET",
    id: `getProfileAttributes`,
    cache: { ttl: 24 * 60 * 60 * 1000 },
    url: `${servicePath}/profileAttributes`,
  });

export const getProfilePronouns = (): AxiosPromise =>
  instance({
    method: "GET",
    id: `getProfilePronouns`,
    cache: { ttl: 24 * 60 * 60 * 1000 },
    url: `${servicePath}/profilePronouns`,
  });

export const getProfileLanguages = (): AxiosPromise =>
  instance({
    method: "GET",
    id: `getProfileLanguages`,
    cache: { ttl: 24 * 60 * 60 * 1000 },
    url: `${servicePath}/profileLanguages`,
  });

export const getIssueType = (): AxiosPromise =>
  instance({
    method: "GET",
    id: `getIssueType`,
    cache: { ttl: 24 * 60 * 60 * 1000 },
    url: `${servicePath}/feedbackFormLog/feedBackFormLogType/list`,
  });

export const getAreaCode = (): AxiosPromise =>
  instance({
    method: "GET",
    id: `getAreaCode`,
    cache: { ttl: 24 * 60 * 60 * 1000 },
    url: `${servicePath}/feedbackFormLog/feedBackFormLogAreaCode/list`,
  });

export const uploadFeedbackImage = (data: { image: File[] }): AxiosPromise => {
  const formData = new FormData();
  data.image.forEach((item: File) => {
    formData.append("image[]", item);
  });

  return instance({
    method: "POST",
    url: `${servicePath}/feedbackFormLog/image`,
    headers: {
      "Content-Type": "multipart/form-data",
    },
    data: formData,
  });
};

export const postFeedback = (data: FeedbackRequestData): AxiosPromise =>
  instance({
    method: "POST",
    url: `${servicePath}/feedbackFormLog`,
    data,
  });
