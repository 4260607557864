import { FC, useContext, useEffect, useState } from 'react';

import {
  Alert,
  AlertColor,
  Box,
  Grid,
  Link,
  Skeleton,
  Snackbar,
  Stack,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { BottomNavMenu, CustomSwiper } from '../../components';
import Header from '../../components/header/header';
import NoResults from '../../components/no-results';

import { ObjectKey } from '../../interfaces/common-interface';
import {
  CardListType,
  GreenApronCardProps,
} from '../../interfaces/green-apron-card-interface';
import { getNewStatus, updateSendCard } from '../../services/green-apron-card';
import { convertDateFullTimeWithFullMonth } from '../../utility';
import styles from './greenApronCard.module.scss';
import GreenApronCardContext from './greenApronCardProvider';
import { useGreenCardFormHook } from './useGreenCardFormHook';

const GreenApronCard: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    list,
    setSelectedCategory,
    setSelectedId,
    loadingList,
    sendValue,
    sendEmailList,
  } = useContext(GreenApronCardContext);
  const [searchParams] = useSearchParams();
  const [noteBar, setNoteBar] = useState<{
    open: boolean;
    status: AlertColor;
    msg: string;
  }>({
    open: false,
    status: 'success',
    msg: '',
  });
  const {
    formValue,
    checkValidation,
    convertFormValueToRequest,
    setInitFormValue,
    cleanFormValue,
  } = useGreenCardFormHook();
  const [isLoading, setIsSending] = useState<boolean>(false);

  const handleClickSelection = (id: string) => {
    setSelectedCategory(id);
    navigate('/green-apron-card/selection');
  };

  const handleClickCard = (id: string, categoryId: string) => {
    setSelectedCategory(categoryId);
    setSelectedId(id);
    navigate('/green-apron-card/send');
  };

  function handleCloseMsgBar() {
    setNoteBar({
      open: false,
      status: 'success',
      msg: '',
    });
    window.history.replaceState(null, '', `/green-apron-card`);
    cleanFormValue();
  }

  useEffect(() => {
    if (searchParams.get('send')) {
      // window.history.replaceState(null, "", "/green-apron-card");
      switch (searchParams.get('send')) {
        case 'success':
          setNoteBar({
            open: true,
            status: 'success',
            msg: t('greenApronCard.successAndSentMsg'),
          });
          break;
        case 'schedule':
          setNoteBar({
            open: true,
            status: 'success',
            msg: t('greenApronCard.successAndScheduleMsg', {
              date: convertDateFullTimeWithFullMonth(location.state.sendTime),
            }),
          });
          break;
        case 'failed':
          // setInitFormValue(location.state);
          setNoteBar({
            open: true,
            status: 'error',
            msg: t('greenApronCard.sendFailMsg'),
          });
          break;
      }
    } else {
      if (searchParams.get('status') === 'notFound') {
        // window.history.replaceState(null, "", "/green-apron-card");
        setNoteBar({
          open: true,
          status: 'error',
          msg: 'Card Not Found',
        });
      }
    }
    window.history.replaceState(null, '', `/green-apron-card`);

    return () => {
      window.history.replaceState(null, '/green-apron-card', ``);
      if (searchParams.get('send') !== 'failed') {
        cleanFormValue();
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const handleResendCard = async () => {
    if (!location.state) return false;
    setInitFormValue(location.state);
    const isValid = checkValidation();
    // console.log("isValid", location.state);
    if (isValid && formValue.id.value) {
      setIsSending(true);
      const request = convertFormValueToRequest();
      try {
        const response = await updateSendCard({
          ...request,
          isSendNow: true,
          id: formValue.id.value,
        });

        if (response.status === 200) {
          if (formValue.sendDate.value.id === 'NOW') {
            setNoteBar({
              open: true,
              status: 'success',
              msg: t('greenApronCard.successAndSentMsg'),
            });
          } else {
            setNoteBar({
              open: true,
              status: 'success',
              msg: t('greenApronCard.successAndScheduleMsg', {
                date: convertDateFullTimeWithFullMonth(
                  formValue.sendDateSchedule.value
                ),
              }),
            });
          }
        }
      } catch (error) {
        setNoteBar({
          open: true,
          status: 'error',
          msg: t('greenApronCard.sendFailMsg'),
        });
        throw error;
      } finally {
        setIsSending(false);
      }
    } else {
      navigate('/green-apron-card/send');
    }
  };

  const LoadingSkeleton = () => {
    return (
      <Stack className={styles.cardListSection}>
        <Box className={styles.cardListSectionTitle}>
          <Skeleton variant="text" height={20} width={200} />
        </Box>
        <Grid container wrap="nowrap" className={styles.cardListSectionContent}>
          {Array.from(Array(4).keys()).map((_, index: number) => (
            <Box key={index} className={styles.cardListSectionCard}>
              <Skeleton
                key={index}
                variant="rounded"
                width="100%"
                height="100%"
              />
            </Box>
          ))}
        </Grid>
      </Stack>
    );
  };

  const [haveNewSentCard, setHaveNewSentCard] = useState<boolean>(false);
  const [haveNewReceivedCard, setHaveNewReceivedCard] =
    useState<boolean>(false);

  useEffect(() => {
    let active = true;
    const getNewCardStatus = async () => {
      try {
        const response = await getNewStatus();
        if (response.status === 200) {
          const data = response.data;
          if (data.newSentCount > 0) {
            setHaveNewSentCard(true);
          }
          if (data.newReceivedCount > 0) {
            setHaveNewReceivedCard(true);
          }
        }
      } catch (error) {
        throw error;
      }
    };

    getNewCardStatus();

    return () => {
      active = false;
    };
  }, []);

  return (
    <>
      <Box className={styles.headerSection}>
        <Header
          enableBackButton
          colouredBackground
          disableBottomBorder
          closeButtonNavigation="/home"
          // closeButtonFunction={() => {
          //   navigate(-1);
          // }}
          title={t('greenApronCard.title')}
        />
        <Box className={styles.actions}>
          <Grid container className={styles.actionsList}>
            <Grid item xs={6}>
              <Box
                component="button"
                className={styles.actionsItem}
                onClick={() => navigate('/green-apron-card/sent')}
              >
                <img src="/assets/images/greenApronCardSentIcon.png" alt="" />
                <Typography
                  variant="body1"
                  align="center"
                  className={haveNewSentCard ? styles.active : undefined}
                >
                  {t('greenApronCard.cardSent')}
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={6}>
              <Box
                component="button"
                className={styles.actionsItem}
                onClick={() => navigate('/green-apron-card/received')}
              >
                <img
                  src="/assets/images/greenApronCardReceivedIcon.png"
                  alt=""
                />
                <Typography
                  variant="body1"
                  align="center"
                  className={haveNewReceivedCard ? styles.active : undefined}
                >
                  {t('greenApronCard.cardReceived')}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Grid item xs className={styles.cardListContainer}>
        {loadingList && <LoadingSkeleton />}
        {!loadingList && list.length === 0 && <NoResults />}
        {list.map((section: CardListType, index: number) => (
          <ListSection
            key={index}
            id={section.id}
            title={section.name}
            lists={section.data}
            onClickList={() => handleClickSelection(section.id)}
            onClickItem={handleClickCard}
          />
        ))}
      </Grid>
      <Snackbar
        open={noteBar.open}
        autoHideDuration={6000}
        className={styles.noteBar}
        onClose={handleCloseMsgBar}
      >
        <Alert
          severity={noteBar.status}
          action={
            noteBar.status !== 'success' && !searchParams.get('status') ? (
              <Link
                component="button"
                variant="body2"
                underline="none"
                onClick={handleResendCard}
              >
                {t('greenApronCard.resend')}
              </Link>
            ) : undefined
          }
        >
          {noteBar.msg}
        </Alert>
      </Snackbar>
      <BottomNavMenu />
    </>
  );
};

interface ListSectionProps {
  id: string;
  title: string;
  lists: Array<GreenApronCardProps>;
  onClickList: () => void;
  onClickItem: (id: string, categoryId: string) => void;
}

const ListSection: FC<ListSectionProps> = (props) => {
  const swiperData = (data: ObjectKey) => {
    const card = data.content as GreenApronCardProps;
    return (
      <Grid
        item
        xs="auto"
        className={styles.cardListSectionCard}
        onClick={() => props.onClickItem(card.id, props.id)}
      >
        <img src={card.url} alt={card.name} />
      </Grid>
    );
  };

  return (
    <Stack className={styles.cardListSection}>
      <Grid container className={styles.cardListSectionTitle}>
        <Grid item xs>
          <Typography variant="h5">{props.title}</Typography>
        </Grid>
        <Grid item xs="auto">
          {/* <IconButton
            id={`selectionBtn-${props.id}`}
            disableRipple
            onClick={props.onClickList}
          >
            <img src={'/assets/images/chevron_right.svg'} alt="" />
          </IconButton> */}
        </Grid>
      </Grid>
      <Box className={styles.cardListSectionContent}>
        <CustomSwiper
          data={props.lists}
          swiperId={`cardListSection-${props.id}`}
          content={swiperData}
          spaceBetween={8}
          isAutoHeight={true}
          isPagination
          isAutoWidth
        />
      </Box>
    </Stack>
  );
};

export default GreenApronCard;
